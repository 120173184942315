

















































































import Base from '@/mixins/Base.vue';
import { ISpObject, ISpObjectResponse } from '@/interfaces/spobject';

const component = Base.extend({
    data() {
        return {

            active: false,
            object: {} as ISpObject,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        save(): void {
            this.post<ISpObjectResponse>('objects', {
                name: this.object.name,
                client_id: this.object.client ? this.object.client.id : null,
            }).then(({ data }) => {
                this.$router.push({ name: 'objects.edit', params: { id: data.data.id.toString() } });
            });
        },
    },
});

export default component;
